import {environment} from "../../../environments/environment";

export const MY_ACCESS_URL = 'accesses';
export const LEARNING_MATERIALS_URL = 'learning-materials';
export const LS_URL = 'ls';
export const EVENTS_URL = 'events';
export const ORDERS_URL = 'orders';
export const PROFILE_URL = 'profile';
export const NEWS_URL = 'news';
export const CLUBS_URL = 'clubs';
export const PORTAL_URL = environment.portalGwoUrl;
export const STUDENT_ZONE_URL = environment.studentZoneUrl;

export const AUTH_PAGE = 'auth';

export const ERROR = {
    BASE: 'error',
    NOT_AUTHENTICATED: 'not-authenticated',
    AUTH_EXPIRED: 'auth-expired',
    PAGE_NOT_FOUND: 'page-not-found',
    ACCESS_DENIED: 'access-denied',
}

export const CHANEL_INFO = {
    BOOKSHOP: {
        name: 'księgarni detalicznej',
        link: environment.chanels.bookshop,
    },
    GRANT: {
        name: 'księgarni dotacyjnej',
        link: environment.chanels.grant
    },
    LS: {
        name: 'Lepszej Szkoły',
        link: environment.chanels.ls,
    },
    PACKET: {
        name: 'pakietów szkolnych',
        link: environment.chanels.packet,
    },
    ENLARGE: {
        name: 'księgarni powiększamy',
        link: environment.chanels.enlarge,
    },
    ACADEMY: {
        name: 'Akademii GWO',
        link: environment.chanels.akademy,
    },
}
